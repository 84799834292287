.workCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family-montserrat);
}
.workCard ::selection {
  background: var(--brand-yellow);
  color: #fff;
}

.workName {
  padding-top: 1rem;
}
.workCard h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #444444;

  padding: 12px;

  text-transform: uppercase;
}
.workImg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.workImg a {
  text-decoration: none;
}
/* .work_bg_1 {
  background-color: #ff9a8b;
  background-image: linear-gradient(
    90deg,
    #ff9a8b 0%,
    #ff6a88 55%,
    #ff99ac 100%
  );
  background: none;
}
.work_bg_2 {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}
.work_bg_3 {
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
} */

.workImg img {
  width: 500px;
  height: 300px;
  box-shadow: 0px 10px 50px 1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.workImg img:hover {
  scale: 1.05;
}

/* small desktop displays */
@media screen and (max-width: 1200px) {
}
/* tablet displays */
@media screen and (max-width: 933px) {
  .workCard h2 {
    font-size: 18px;
  }
}

/* mobile displays 1 */
@media screen and (max-width: 600px) {
  .workImg img {
    width: 300px;
    height: 180px;
    border-radius: 6px;
  }
  .workCard h2 {
    font-size: 16px;
    padding: 12px 12px 0 12px;
  }
}
/* mobile displays 2 */
@media screen and (max-width: 380px) {
  .workImg {
    /* width: 250px;
    height: 160px; */
  }
}
