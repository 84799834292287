.value_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 24px;

  width: 300px;
  height: 300px;

  background: #ffffff;
  box-shadow: 0px 10px 20px #eeeeee;
  border-radius: 4px;

  font-family: var(--font-family-montserrat);
  transition: all 0.4s ease-in-out;
}
.value_card:hover {
  scale: 1.1;
}
.value_card ::selection {
  background: var(--brand-yellow);
  color: #fff;
}

.value_card h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;

  color: #333333;

  text-transform: uppercase;
}

.value_card img {
  width: 70px;
}

/* tablet displays 1  */
@media screen and (max-width: 900px) {
  .value_card img {
    width: 60px;
  }
  .value_card h2 {
    font-size: 17px;
  }

  .value_card {
    gap: 5px;

    width: 200px;
    height: 200px;
  }
}

/* mobile displays 1 */
@media screen and (max-width: 600px) {
  .value_card img {
    width: 40px;
  }
  .value_card h2 {
    font-size: 16px;
  }

  .value_card {
    gap: 2px;

    width: 160px;
    height: 160px;
  }
}
/* mobile displays 2 */
@media screen and (max-width: 404px) {
  .value_card img {
    width: 35px;
  }
  .value_card h2 {
    font-size: 13px;
  }

  .value_card {
    width: 130px;
    height: 130px;
  }
}
/* mobile displays 3 */
@media screen and (max-width: 320px) {
  .value_card img {
    width: 30px;
  }
  .value_card h2 {
    font-size: 12px;
  }

  .value_card {
    width: 110px;
    height: 110px;
  }
}
