._navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5.625rem;
  padding: 0 3.5rem;
  background: transparent;

  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  scroll-behavior: smooth;

  transition: 0.5s ease-in-out;

  font-family: var(--font-family-montserrat);
}

.bg {
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0px 4px 100px 10px rgba(0, 0, 0, 0.04);
}
.mjay_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mjay_logo img {
  width: 140px;
  height: auto;
}

._navbar a {
  text-decoration: none;
  color: inherit;
}

.nav_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.125rem;
}
.nav_menu a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}
.nav_menu button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  box-sizing: border-box;

  width: 150px;
  height: 40px;

  background: rgba(255, 255, 255, 0.98);

  color: #000000;
  border: 2px solid #000000;

  cursor: pointer;

  transition: all 0.4s ease-in-out;
}
.nav_menu button:hover {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.nav_toggler div {
  width: 1.7rem;
  height: 0.13rem;
  margin: 0.2rem;
  background: #000000;
  transition: 0.4s ease-in-out;
}
.nav_toggler div:nth-child(2) {
  width: 1rem;
}

.nav_toggler {
  cursor: pointer;
  display: none;
}
._navbar ::selection {
  background: #000;
  color: #fff;
}

@media screen and (max-width: 900px) {
  .nav_toggler {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mjay_logo img {
    width: 130px;
    height: auto;
  }
  .nav_menu {
    position: fixed;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(6px);
    transform: translateX(100%);
    transition: 0.5s ease-in-out;
    overflow-y: auto;
  }
}

/* Nav Activation Class */
.nav_active {
  transform: translateX(0);
}

/* Toggle icon animation */
.toggle .line1 {
  transform: rotate(-45deg) translate(-6px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-6px, -5px);
}

/* mobile device 1*/
@media screen and (max-width: 600px) {
  ._navbar {
    height: 4.8rem;
  }
}

@media screen and (max-width: 500px) {
  ._navbar {
    padding: 0 1.75rem;
  }
  .mjay_logo img {
    width: 100px;
  }
}

/*  mobile device 2 */

@media screen and (max-width: 320px) {
  ._navbar {
    height: 4.5rem;
  }
  .mjay_logo img {
    width: 90px;
    height: auto;
  }
}
