.package_card {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 363px;

  padding: 3.9rem;
  gap: 2.5rem;

  box-shadow: 0px 0px 20px 0px #eee;
  font-family: var(--font-family-montserrat);
  transition: all 0.3s ease-in-out;
}
.package_card:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 20px 0px #eee;
}

.package_card img {
  width: 90%;
}

.package_card h2 {
  display: inline-flex;
  padding: 5.539px 13.848px;
  justify-content: center;
  align-items: center;
  gap: 9.232px;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.695px;
  letter-spacing: 0.16px;

  border-radius: 5.539px;
  background: #d4c8fe;
  background: #ffdec8;
  background: #d8f5ff;
}

.package_card ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1rem;

  align-self: flex-start;
}

.package_card li {
  color: #444;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.child-feature {
  list-style: circle;
  margin-left: 1rem;
}

.price h3 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.price h3:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin-top: 0.5rem;

  text-decoration: line-through #ff0000;
}

.package_card a {
  text-decoration: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 9.232px;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.16px;

  border-radius: 4px;

  padding: 13px 40px;
  transition: all 0.3s ease-in-out;
}

.package_card a:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 20px 0px #eee;
}

@media screen and (max-width: 808px) {
  .package_card {
    width: 310px;
  }
}

@media screen and (max-width: 600px) {
  .package_card {
    width: 290px;
    padding: 3rem;
  }
  .package_card a {
    font-size: 14px;
    letter-spacing: 0.1px;

    border-radius: 3px;

    padding: 10px 40px;
  }
}
@media screen and (max-width: 320px) {
  .package_card {
    width: 250px;
  }
}
