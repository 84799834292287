.accordion-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  font-family: var(--font-family-montserrat);
}
.accordion-container ::selection {
  background: var(--brand-yellow);
  color: #fff;
}
.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-title {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #444444;
  cursor: pointer;
  transition: all 1s ease;

  margin: 20px 0;
}
.accordion-container h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}

.accordion-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  font-size: 14px;
  color: #555;
}

.content {
  padding: 0 0 10px 0;
  background-color: transparent;
  color: #7b7b7b;
  transition: max-height 0.5s ease;
}

.content h3 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  letter-spacing: 0.015em;
}

.content.active {
  max-height: 500px;
  transition: max-height 4s ease;
}

/* small desktop displays */
@media screen and (max-width: 1050px) {
  .accordion-container {
    max-width: 700px;
  }
}
/* tablet displays */
@media screen and (max-width: 850px) {
  .accordion-container h2 {
    font-size: 16px;
    line-height: 24px;
  }
  .content h3 {
    font-size: 14px;
    line-height: 24px;
  }

  .accordion-title {
    margin: 18px 0;
  }
}

/* mobile displays- 1 */
@media screen and (max-width: 600px) {
  .accordion-container h2 {
    font-size: 16px;
  }
  .content h3 {
    font-size: 14px;
    line-height: 22px;
  }

  .accordion-title {
    margin: 18px 0;
  }
}
/* mobile displays -2 */
@media screen and (max-width: 330px) {
  .accordion-container h2 {
    font-size: 15px;
  }
  .content h3 {
    font-size: 13px;
    line-height: 20px;
  }
}
