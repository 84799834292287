.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 6rem 3.5rem;

  background: #fffefb;
}

.faq .title {
  align-self: flex-start;
}
._accordion {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  margin-top: 5rem;
}

@media screen and (max-width: 900px) {
  .faq {
    padding: 3rem 2rem;
  }
  ._accordion {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 600px) {
  .faq {
    padding: 3rem 2rem;
  }
  ._accordion {
    margin-top: 2rem;
  }
}
