* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.cursor {
  background-color: #111;
  height: 32px;
  width: 32px;
  border-radius: 999px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
}
@media screen and (max-width: 850px) {
  .cursor {
    display: none;
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 47%;
  z-index: 10;
}
.loader_logo img {
  width: 130px;
  height: 50px;
  margin-bottom: 1rem;
}

.loader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  background: var(--brand-yellow);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    top: 47%;
    left: 47%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    top: 45%;
    left: 45%;
  }
}
@media screen and (max-width: 582px) {
  .container {
    top: 45%;
    left: 43%;
  }
}
@media screen and (max-width: 500px) {
  .container {
    top: 45%;
    left: 38%;
  }
}
@media screen and (max-width: 400px) {
  .container {
    top: 45%;
    left: 33%;
  }
}

@media screen and (max-width: 338px) {
  .container {
    top: 45%;
    left: 31%;
  }
}
@media screen and (max-width: 300px) {
  .container {
    top: 45%;
    left: 30%;
  }
}
