.mjay_contact_form_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: var(--font-family-montserrat);
}

.mjay_contact_form_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 700px;
  height: 700px;

  border-radius: 10px;
  background: linear-gradient(150deg, #fff 50%, #fad22f 50%);
}

.mjay_contact_form_div h1 {
  font-weight: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.close_button {
  position: absolute;
  top: 25px;
  right: 25px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #aaa;
}

/* tablet displays */
@media screen and (max-width: 850px) {
  .mjay_contact_form_div {
    width: 550px;
    height: 700px;
  }
  .mjay_contact_form_div h1 {
    font-weight: 10px;
  }
}

/* mobile displays 1*/
@media screen and (max-width: 600px) {
  .mjay_contact_form_div h1 {
    font-size: 25px;
    margin-bottom: 1rem;
  }
  .mjay_contact_form_div {
    width: 350px;
    height: 600px;
  }
}

@media screen and (max-width: 375px) {
  .mjay_contact_form_div {
    width: 320px;
  }
}
@media screen and (max-width: 340px) {
  .mjay_contact_form_div {
    width: 280px;
  }
}
