.ourTeamCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  gap: 14px;

  font-family: var(--font-family-montserrat);
}

.ourTeamCard img {
  width: 300px;
}

.ourTeamCard h2 {
  color: #444;

  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.ourTeamCard h3 {
  color: #939393;

  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

@media screen and (max-width: 1090px) {
  .ourTeamCard img {
    width: 250px;
  }
  .ourTeamCard h2 {
    font-size: 22px;
  }

  .ourTeamCard h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .ourTeamCard img {
    width: 200px;
  }
  .ourTeamCard h2 {
    font-size: 20px;
  }

  .ourTeamCard h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .ourTeamCard h3 {
    line-height: 20px;
  }
}
