.testimonialCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  width: 350px;
  min-height: 450px;

  padding: 30px;

  background: #ffffff;
  box-shadow: 0px 10px 20px #eeeeee;
  border-radius: 61px;

  font-family: var(--font-family-montserrat);
}
.testimonialCard ::selection {
  background: var(--brand-yellow);
  color: #fff;
}
.testi_founder {
  text-align: center;
}
.testimonialCard img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  object-fit: cover;

  border: 4px solid var(--brand-yellow);
  border-radius: 123px;
}

.testimonialCard p {
  width: 260px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.015em;

  color: #6b644f;
}

.testimonialCard h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  margin: 0;
  letter-spacing: 0.015em;

  color: #625b47;
}

.testimonialCard h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  opacity: 70%;

  letter-spacing: 0.015em;

  color: #444444;
}
/* tablet displays */
@media screen and (max-width: 850px) {
  .testimonialCard {
    width: 300px;
    min-height: 400px;
    border-radius: 50px;
  }

  .testimonialCard img {
    width: 80px;
    height: 80px;
  }

  .testimonialCard p {
    width: 220px;

    font-size: 12px;
    line-height: 18px;
  }

  .testimonialCard h1 {
    font-size: 18px;
    line-height: 24px;
  }

  .testimonialCard h2 {
    font-size: 16px;
  }
}

/* mobile displays -1  */
@media screen and (max-width: 600px) {
  .testimonialCard {
    width: 250px;
    min-height: 350px;
    border-radius: 40px;
    gap: 15px;
  }

  .testimonialCard img {
    width: 75px;
    height: 75px;
  }

  .testimonialCard p {
    width: 180px;

    font-size: 11px;
  }

  .testimonialCard h1 {
    font-size: 16px;
    line-height: 20px;
  }

  .testimonialCard h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 300px) {
  .testimonialCard {
    width: 230px;
    min-height: 330px;
    border-radius: 35px;
  }
}
