.serviceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  width: 308px;
  height: 360px;

  background: #ffffff;
  box-shadow: 0px 10px 20px #eeeeee;
  border-radius: 4px;

  font-family: var(--font-family-montserrat);
  transition: all 0.4s ease-in-out;

  cursor: pointer;
}
.serviceCard:hover {
  scale: 1.1;
}
.serviceCard ::selection {
  background: var(--brand-yellow);
  color: #fff;
}

.serviceCard img {
  width: 75px;
  height: 75px;
}

.serviceCard h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;

  color: #333333;
}

.serviceCard p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  text-align: center;

  color: #7b7b7b;
}

/* medium pc displays */

@media screen and (max-width: 1200px) {
  .serviceCard {
    width: 288px;
    height: 322px;
  }
  .serviceCard h1 {
    line-height: 100%;
  }
}
/* Small pc displays */

@media screen and (max-width: 1050px) {
  .serviceCard {
    padding: 12px 24px;

    width: 268px;
  }
  .serviceCard img {
    width: 65px;
    height: 65px;
  }
  .serviceCard p {
    font-size: 13px;
    line-height: 140%;
  }
}
/* tablet displays - 1 */

@media screen and (max-width: 1000px) {
  .serviceCard {
    padding: 5px 20px;
  }
  .serviceCard img {
    width: 60px;
    height: 60px;
  }

  .serviceCard h1 {
    font-size: 22px;
  }

  .serviceCard p {
    font-size: 13px;
    line-height: 140%;
  }
}

/* tablet displays - 2 */

@media screen and (max-width: 875px) {
  .serviceCard {
    padding: 5px 20px;

    width: 240px;
    height: 300px;
  }
  .serviceCard img {
    width: 60px;
    height: 65px;
  }

  .serviceCard h1 {
    font-size: 21px;
  }

  .serviceCard p {
    font-size: 12px;
    line-height: 140%;
  }
}
/* mobile displays - 1 */

@media screen and (max-width: 630px) {
  .serviceCard {
    padding: 5px 20px;
    gap: 10px;

    width: 218px;
  }
  .serviceCard img {
    width: 60px;
    height: 60px;
  }

  .serviceCard h1 {
    font-size: 20px;
    padding: 2px 0;
  }

  .serviceCard p {
    font-size: 12px;
  }
}
/* mobile displays - 2 */
@media screen and (max-width: 380px) {
  .serviceCard img {
    width: 50px;
    height: 50px;
  }
  .serviceCard {
    width: 198px;
    height: 280px;
  }

  .serviceCard p {
    font-size: 11px;
    line-height: 140%;
  }
}
