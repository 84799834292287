.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 3.5rem;

  height: 400px;

  background: #fbf4e2;

  font-family: var(--font-family-montserrat);
}
.footer ::selection {
  background: var(--brand-yellow);
  color: #fff;
}
.footer_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 100px;
}

.footer_container h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin: 0;

  text-align: center;

  letter-spacing: 0.015em;

  text-transform: capitalize;
}

.footer_container h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.footer_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer_logo img {
  width: 150px;
  height: auto;
}
.footer_logo p {
  width: 260px;

  line-height: 23px;

  font-style: normal;
  font-weight: 500;
}
.footer_content {
  display: flex;
  align-items: flex-start;

  gap: 190px;
}

.horizontal_row_sm {
  height: 1.2px;
  width: 100%;
  background-color: #333333;
}

.footer_services,
.footer_links,
.footer_contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 16px;
}
.footer_links h3 {
  text-transform: capitalize;
}
.footer_links a {
  color: inherit;
  text-decoration: none;
}

.footer_contact h3:nth-of-type(1),
.footer_contact h3:nth-of-type(3) {
  font-weight: 600;
}

.horizontal_row_lg {
  width: 100%;
  height: 1px;

  background-color: #fad22f;
}

.copyrights {
  margin: auto 0;
}
.footer_logo {
  gap: 20px;
}
.socials a {
  padding: 10px;
  margin: 10px;
  border: solid 1px #d0cbb4;

  border-radius: 5px;
  color: inherit;
  text-decoration: none;
}

.socials i {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.socials i:hover {
  transform: scale(1.2);
}
.copyrights {
  background: #fbf4e2;
  text-align: center;

  font-family: var(--font-family-montserrat);
}
.copyrights p {
  padding: 15px 10px;
}

/* laptop displays */
@media screen and (max-width: 1200px) {
  .footer_container {
    gap: 70px;
  }

  .footer_content {
    gap: 80px;
  }
}
/* tablet displays 1 */
@media screen and (max-width: 1000px) {
  .footer {
    padding: 3.5rem;

    height: inherit;
  }

  .footer_container {
    gap: 50px;
  }

  .footer_container h2 {
    font-size: 18px;
  }

  .footer_container h3 {
    font-size: 14px;
  }

  .footer_services,
  .footer_links,
  .footer_contact {
    gap: 8px;
  }

  .footer_logo p {
    font-size: 13px;
  }
}

/* tablet displays 2*/
@media screen and (max-width: 870px) {
  .footer_content {
    gap: 60px;
  }
}

@media screen and (max-width: 820px) {
  .footer_container {
    gap: 30px;
  }

  .footer_content {
    gap: 40px;
  }

  .footer_container h2 {
    font-size: 19px;
  }

  .footer_logo img {
    width: 130px;
    height: auto;
  }
  .footer_logo p {
    font-size: 14px;
  }
  .copyrights p {
    font-size: 14px;
  }
}
/* tablet displays 3*/
@media screen and (max-width: 760px) {
  .footer_container {
    flex-direction: column;
    align-items: center;

    gap: 60px;
  }

  .footer_content {
    gap: 80px;
  }
  .footer_container h2 {
    font-size: 20px;
  }

  .footer_logo {
    gap: 30px;
  }
  .footer_logo p {
    width: 420px;
    line-height: 30px;

    font-size: 16px;
  }
}
/* mobile displays 1*/
@media screen and (max-width: 600px) {
  .footer_container {
    align-items: center;
  }

  .footer_content {
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 60px;
  }

  .footer_logo p {
    width: 300px;
  }
  .copyrights p {
    font-size: 12px;
    line-height: 18px;
  }
}
/* mobile displays 2*/
@media screen and (max-width: 425px) {
  .footer_content {
    gap: 20px 70px;
  }

  .footer_logo p {
    width: 250px;
    line-height: 30px;

    font-size: 15px;
  }
}
/* mobile displays 3*/
@media screen and (max-width: 300px) {
  .footer_content {
    gap: 20px 40px;
  }
  .footer_logo p {
    width: 200px;
  }
  .socials a {
    margin: 5px;
  }
}
